import { gql } from "graphql-request";

/*
  ${imageFragment}
  ${linkToUnionFragment}
*/
const bannerFragment = gql`
  fragment Banner on Banner {
    id
    utmSource
    domains
    slides {
      id
      searchTermString
      title
      ctaLabel
      ctaLink {
        to {
          ...LinkToUnion
        }
      }
      displayDuration
      imageDesktop {
        ...ImageParts
      }
      imageMobile {
        ...ImageParts
      }
    }
  }
`;

export default bannerFragment;

import { gql } from "graphql-request";
import { Banner } from "../vo";
import bannerFragment from "../fragments/bannerFragment";
import linkToUnionFragment from "../fragments/linkToUnionFragment";
import imageFragment from "../fragments/imageFragment";
import { hygraphApi } from "./hygraphApi";

type BannerData = {
  banner: Banner;
};

export const bannerApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    getBanner: builder.query<BannerData | null, string>({
      providesTags: ["languageDependent"],
      query: id => {
        return {
          document: gql`
            ${imageFragment}
            ${linkToUnionFragment}
            ${bannerFragment}
            query BannerData($id: ID!) {
              banner(where: { id: $id }) {
                ...Banner
              }
            }
          `,
          variables: {
            id,
          },
        };
      },
    }),
  }),
});

export const { useGetBannerQuery } = bannerApi;
